<ng-container *ngIf="data?.internalTitle === SectionComponentsEnum.SECTIONSERVICES">
  <remax-tile-list [tiles]="data.tiles"></remax-tile-list>
</ng-container>
<ng-container *ngIf="data?.internalTitle === SectionComponentsEnum.SECTIONHOME">
  <remax-home-section [serviceTiles]="data.tiles"></remax-home-section>
</ng-container>
<ng-container *ngIf="data?.internalTitle === SectionComponentsEnum.SECTIONOFFERS">
  <remax-offers-section> </remax-offers-section>
</ng-container>
<ng-container *ngIf="data?.internalTitle === SectionComponentsEnum.SEARCHREQUEST">
  <remax-search-request-form> </remax-search-request-form>
</ng-container>
<ng-container *ngIf="data?.internalTitle === SectionComponentsEnum.TIPSTERFORM">
  <remax-tipster-form> </remax-tipster-form>
</ng-container>
<ng-container *ngIf="data?.internalTitle === SectionComponentsEnum.GOOGLEMAP">
  <div class="contact__map-wrapper">
    <img src="../../assets/contact/google-map.png" />
  </div>
</ng-container>
<ng-container *ngIf="data?.internalTitle === SectionComponentsEnum.OFFERSNATIONAL">
  <remax-offers-national></remax-offers-national>
</ng-container>
