import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SectionComponentsEnum } from '../section/model/section-components.enum';
import { TileListComponent } from '../tile-list/tile-list.component';
import { HomeSectionComponent } from '../../../home/components/home-section/home-section.component';
import { OffersSectionComponent } from '../../../home/components/offers-section/offers-section.component';
import { SearchRequestFormComponent } from '../search-request-form/search-request-form.component';
import { TipsterFormComponent } from '../tipster-form/tipster-form.component';
import { OffersNationalComponent } from '../../../offers/offers-national.component';

@Component({
  imports: [
    CommonModule,
    FontAwesomeModule,
    TileListComponent,
    HomeSectionComponent,
    OffersSectionComponent,
    SearchRequestFormComponent,
    TipsterFormComponent,
    OffersNationalComponent
  ],
  selector: 'remax-local-reference-section',
  templateUrl: './local-reference.component.html',
  styleUrls: ['./list-info-section.component.scss']
})
export class LocalReferenceComponent {
  @Input() data: any;

  SectionComponentsEnum = SectionComponentsEnum;
}
