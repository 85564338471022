<div appScrollManager>
  <div appScrollSection="offer">
    <div class="container mx-auto px-4 pt-16">
      <div class="mt-4 p-4">
        <form [formGroup]="searchForm" name="searchForm" class="grid grid-flow-row  sm:grid-flow-col gap-y-4 gap-x-4">
          <select
            id="realEstateType"
            name="realEstateType"
            autocomplete="realEstateType"
            formControlName="realEstateType"
          >
            <option *ngFor="let option of realEstateTypes" [selected]="true" [value]="option.value">{{ option.label }}</option>
          </select>

          <select id="marketingType" name="marketingType" autocomplete="marketingType" formControlName="marketingType">
            <option [selected]="true">Kauf</option>
            <option>Miete</option>
          </select>

          <select
            *ngIf="searchForm.controls['marketingType'].value === 'Kauf'"
            id="sorting"
            name="sorting"
            autocomplete="sorting"
            formControlName="sorting"
          >
            <option [selected]="true">Preis absteigend</option>
            <option>Preis aufsteigend</option>
          </select>

          <select
            *ngIf="searchForm.controls['marketingType'].value === 'Miete'"
            id="sorting"
            name="sorting"
            autocomplete="sorting"
            formControlName="sorting"
          >
            <option [selected]="true">Miete absteigend</option>
            <option>Miete aufsteigend</option>
          </select>
          <button (click)="setFilter()">Suchen</button>
        </form>
      </div>
      <div
        class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 auto-cols-max gap-x-6 gap-y-8 mt-10 pb-32"
        *ngIf="(estates$ | async)?.length > 0"
      >
        <remax-object-tile
          *ngFor="let estate of estates$ | async"
          [estateObject]="estate"
          [estateImage]="getImageUrl(estate)"
        ></remax-object-tile>
      </div>
      <div class="text-center p-40" *ngIf="(estates$ | async)?.length === 0">
        <p>Keine Treffer gefunden</p>
      </div>
    </div>
    <!-- <remax-section [data]="section.fields" *ngFor="let section of (offers$ | async)?.sections"> </remax-section> -->
  </div>
</div>
