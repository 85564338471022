import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ContentfulService } from '../shared/services/contentful/contentful_old.service';
import { FooterComponent } from '../shared/components/footer/footer.component';
import { HeroSectionComponent } from '../shared/components/hero-section/hero-section.component';
import { NavigationComponent } from '../shared/components/navigation/navigation.component';
import { SectionComponent } from '../shared/components/section/section.component';
import { LocalizationService } from '../shared/services/localization/localization.service';
import { ScrollAnchorDirective } from '../shared/utils/directives/scroll-anchor.directive';
import { ScrollManagerDirective } from '../shared/utils/directives/scroll-manager.directive';
import { ScrollSectionDirective } from '../shared/utils/directives/scroll-section.directive';
import { ObjectTileComponent } from './components/object-tile/object-tile.component';
import { selectAllEstates } from './store/estates.selectors';

import { EstateObject } from './models/estate-object.model';
import { Store } from '@ngrx/store';
import { getEstates,setFilter, setResourceId } from './store/estates.actions';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { OrderByPipe } from '../shared/pipes/orderBy.pipe';
import { TypePage } from '../models/contentful-content-types/page';


@Component({
    imports: [
        SectionComponent,
        CommonModule,
        NavigationComponent,
        HeroSectionComponent,
        FooterComponent,
        ScrollAnchorDirective,
        ScrollManagerDirective,
        ScrollSectionDirective,
        ObjectTileComponent,
        ReactiveFormsModule,
        OrderByPipe,
    ],
    selector: 'remax-offers-national',
    templateUrl: './offers-national.component.html',
    styleUrls: ['./offers-national.component.scss']
})
export class OffersNationalComponent implements OnInit {
  offers$: Observable<TypePage> = this.contentfulService.loadPage('PAGE: Offers', this.localizationService.locale$.value);
  estates$: Observable<EstateObject[]> = this.store.select(selectAllEstates);
  realEstateTypes = [
    {
      label: 'Alle',
      value: 'Alle'
    },
    {
      label: 'Haus',
      value: 'Haus'
    },
    {
      label: 'Wohnung',
      value: 'Wohnung'
    },
    {
      label: 'Grundstück',
      value: 'Grundstueck'
    },
    {
      label: 'Büro/Praxen',
      value: 'Buero_Praxen'
    },
    {
      label: 'Laden/Einzelhandel',
      value: 'Einzelhandel'
    }
    // {
    //   label: 'Gastgewerbe',
    //   value: 'Gastgewerbe'
    // },
    // {
    //   label: 'Hallen/Lager/Produktion',
    //   value: 'Hallen_Lager_Produktion'
    // },
    // {
    //   label: 'Land/Forstwirtschaft',
    //   value: 'Land_Forstwirtschaft'
    // },
    // {
    //   label: 'Freizeitimmobilie(gewerblich)',
    //   value: 'Freizeitimmobilie'
    // }
  ];
  searchForm = this.fb.group({
    realEstateType: ['Alle'],
    marketingType: ['Kauf'],
    sorting: ['Preis absteigend']
  });

  constructor(
    private contentfulService: ContentfulService,
    private localizationService: LocalizationService,
    private store: Store,
    private fb: FormBuilder,
  ) {
    this.localizationService.locale$.subscribe((locale) => {
      this.offers$ = this.contentfulService.loadPage('PAGE: Offers', locale);
    });

    this.searchForm.controls['sorting'].valueChanges.subscribe((change) => {
      this.sortEstates(change);
    });
  }

  ngOnInit(): void {
    this.setFilter();
  }

  setFilter() {
    let filter;
    if (this.searchForm.controls.realEstateType.value === 'Alle') {
      filter = {
        status: [{ op: '=', val: '1' }],
        vermarktungsart: [{ op: '=', val: [this.searchForm.controls.marketingType.value] }],
        nutzungsart: [{ op: 'in', val: ['Wohnen','Gewerbe'] }]
      };
      this.store.dispatch(setFilter({ filter }));
      this.store.dispatch(setResourceId({ resourceid: null }));
      this.store.dispatch(getEstates());
    } else {
      filter = {
        status: [{ op: '=', val: '1' }],
        objektart: [{ op: '=', val: [this.searchForm.controls.realEstateType.value] }],
        vermarktungsart: [{ op: '=', val: [this.searchForm.controls.marketingType.value] }],
        nutzungsart: [{ op: 'in', val: ['Wohnen','Gewerbe'] }]
      };
      this.store.dispatch(setFilter({ filter }));
      this.store.dispatch(setResourceId({ resourceid: null }));
      this.store.dispatch(getEstates());
    }
  }
  getImageUrl(estate: EstateObject) {
    if (estate?.images) {
      return estate?.images?.find((obj) => obj.type === 'Titelbild').url;
    }
  }

  sortEstates(value) {
    if (value === 'Preis aufsteigend') {
      this.estates$ = this.estates$.pipe(
        map((estates) =>
          [...estates].sort(
            (a, b) => Number(a?.kaufpreis?.replace(/[.€]/g, '')) - Number(b?.kaufpreis?.replace(/[.€]/g, ''))
          )
        )
      );
    } else if (value === 'Preis absteigend') {
      this.estates$ = this.estates$.pipe(
        map((estates) =>
          [...estates].sort(
            (a, b) => Number(b?.kaufpreis?.replace(/[.€]/g, '')) - Number(a?.kaufpreis?.replace(/[.€]/g, ''))
          )
        )
      );
    } else if (value === 'Miete absteigend') {
      this.estates$ = this.estates$.pipe(
        map((estates) =>
          [...estates].sort(
            (a, b) => Number(b?.kaltmiete?.replace(/[.€]/g, '')) - Number(a?.kaltmiete?.replace(/[.€]/g, ''))
          )
        )
      );
    } else if (value === 'Miete aufsteigend') {
      this.estates$ = this.estates$.pipe(
        map((estates) =>
          [...estates].sort(
            (a, b) => Number(b?.kaltmiete?.replace(/[.€]/g, '')) - Number(a?.kaltmiete?.replace(/[.€]/g, ''))
          )
        )
      );
    }
  }
}
